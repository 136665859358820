import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

interface Props {

}

const Main: React.FunctionComponent<Props> = (props: Props) => {

  useEffect(() => {
    document.title = 'Описание, возможности, инструкции';
  })

  return (
    <main className="page-content">
      <div className="page-content__wrapper page-content__wrapper--main container">
        <section className="info-project__info info-project__info--about">
          <h2 className="header">О сервисе</h2>

          <p className="text info-project__text">
            Данный сервис предназначен для отслеживания цен и количества товаров компаний-конкурентов ООО «ЕВРОТЕК».
            Все номенклатурные позиции, по которым отслеживается изменение цены и наличия можно посмотреть в
            разделе <Link className="link" to="/prices">«Цены»</Link> меню. Также в данном разделе можно добавить
            дополнительные номенклатурные позиции.
          </p>

          <p className="text info-project__text">
            Динамику изменения цен и наличия товаров можно посмотреть, кликнув на товар в таблице товаров.
          </p>
        </section>

        <section className="info-project__info info-project__info--capabilities">
          <h2 className="header">Возможности</h2>
          <ul className="capabilities-list">
            <li className="capabilities-list__item">
              Отслеживание цен с сайтов конкурентов
            </li>
            <li className="capabilities-list__item">
              Обновление информации о ценах на товар 1 раз в час
            </li>
            <li className="capabilities-list__item">
              Просмотр статистики за определенный период
            </li>
            <li className="capabilities-list__item">
              Возможность подключить уведомления при изменении цены на определенный товар у конкурента
            </li>
          </ul>
        </section>

        <section className="info-project__ifo">
          <h2 className="header">Инструкции</h2>
          <a className="info-project__download-instruction" href="#">
            <svg width="152" height="204" viewBox="0 0 152 204" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M72.0021 102.04C70.0229 95.6648 70.0625 83.3531 71.2104 83.3531C74.5354 83.3531 74.2187 98.0555 72.0021 102.04ZM71.3292 120.846C68.2813 128.895 64.4813 138.098 60.0875 145.828C67.3313 143.039 75.525 138.975 84.9854 137.102C79.9583 133.277 75.1292 127.779 71.3292 120.846ZM34.0812 170.571C34.0812 170.89 39.3063 168.42 47.8958 154.554C45.2438 157.064 36.3771 164.316 34.0812 170.571ZM98.1667 63.75H152V194.437C152 199.737 147.765 204 142.5 204H9.5C4.23542 204 0 199.737 0 194.437V9.5625C0 4.26328 4.23542 0 9.5 0H88.6667V54.1875C88.6667 59.4469 92.9417 63.75 98.1667 63.75ZM95 132.202C87.0833 127.341 81.8188 120.647 78.0979 110.766C79.8792 103.395 82.6896 92.1984 80.5521 85.1859C78.6917 73.4719 63.7687 74.6273 61.6312 82.4766C59.6521 89.768 61.4729 100.048 64.8375 113.156C60.2458 124.153 53.4771 138.895 48.6875 147.342C48.6479 147.342 48.6479 147.382 48.6083 147.382C37.8813 152.92 19.475 165.112 27.0354 174.476C29.2521 177.225 33.3688 178.46 35.5458 178.46C42.6313 178.46 49.6771 171.288 59.7313 153.837C69.9438 150.45 81.1458 146.227 91.0021 144.593C99.5917 149.295 109.646 152.362 116.335 152.362C127.894 152.362 128.685 139.612 124.133 135.07C118.631 129.652 102.64 131.205 95 132.202ZM149.229 41.8359L110.438 2.78906C108.656 0.996094 106.242 0 103.708 0H101.333V51H152V48.5695C152 46.0594 151.01 43.6289 149.229 41.8359ZM119.898 143.557C121.521 142.481 118.908 138.816 102.956 139.971C117.642 146.266 119.898 143.557 119.898 143.557Z"
                fill="#BE1E2D"/>
            </svg>
          </a>
        </section>
      </div>
    </main>
  );
}

export default Main;
